@font-face {
  font-family: Aeonik;
  src: url(./fonts/Aeonik-Regular.woff2);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url(./fonts/Aeonik-Medium.woff2);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

#app,
html,
body {
  font-family: Aeonik, Rubik, HelveticaNeue-Regular, Helvetica Neue Regular, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
}

/* custom component classes, can be overwritten by utility classes */
@layer components {
  /* Buttons */
  .button {
    padding-bottom: 2px;
    @apply transition-all duration-500 w-60 max-w-full h-14 px-2 rounded-full text-center no-underline flex justify-center items-center cursor-pointer border-solid border-2 font-medium text-18px leading-90%;
  }

  .button:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  .button:focus {
    @apply outline-none ring-2 ring-offset-2;
  }

  .btn-charcoal {
    @apply border-charcoal bg-charcoal text-white hover:border-yellow hover:bg-yellow hover:text-charcoal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow;
  }

  .btn-charcoal-alt {
    @apply border-charcoal bg-charcoal text-white hover:border-white hover:bg-white hover:text-charcoal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white;
  }

  .btn-charcoal-line {
    @apply border-charcoal bg-transparent text-charcoal hover:border-yellow hover:bg-yellow hover:text-charcoal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow;
  }

  .btn-white {
    @apply border-white bg-white text-charcoal hover:border-yellow hover:bg-yellow hover:text-charcoal focus:outline-none focus:ring-0 focus:ring-offset-0;
  }
}

.sign-in-with-google-btn {
  font-family: Roboto, Rubik, HelveticaNeue-Regular, Helvetica Neue Regular, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hamburger-transition-1 {
  transition: transform 0.2s linear 0s, color 0.3s linear 0s;
}

.hamburger-transition-2 {
  transition: transform 0.2s linear 0.2s, color 0.3s linear 0s;
}
.stroked-text-charcoal {
  -webkit-text-stroke: 2px #171717;
}

/*
    The cards on the dashboard...
*/
.dashboard_card,
.dashboard_card a {
  transition: all 0.2s ease-in-out !important;
}
